import { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { useAppContext } from "@/context/Context";
import logo from "../../public/images/logo/logo.png";
import Nav from "./Nav";
import useAuthStatus from '/hooks/useAuthStatus';
import useLogout from '/hooks/useLogout';
import UserMenu from "./UserMenu";

const Header = ({ headerTransparent, headerSticky, btnClass }) => {
  const { activeMobileMenu, setActiveMobileMenu } = useAppContext();
  const [isSticky, setIsSticky] = useState(false);
  const { isAuthenticated, user } = useAuthStatus();
  const logout = useLogout();
  const baseUrl = process.env.NEXT_PUBLIC_APIURL;

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;
      if (scrolled > 200) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header
        className={`rainbow-header header-default ${headerTransparent} ${headerSticky} ${
          isSticky ? "sticky" : ""
        }`}
      >
        <div className="container position-relative">
          <div className="row align-items-center row--0">
            <div className="col-lg-3 col-md-6 col-6">
              <div className="logo">
                <Link href="/" legacyBehavior>
                  <a>
                    <Image
                      className="logo-light"
                      src={logo}
                      width={201}
                      height={35}
                      alt="ChatBot Logo"
                    />
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-lg-9 col-md-6 col-6 position-static">
              <div className="header-right">
                <nav className="mainmenu-nav d-none d-lg-block">
                  <Nav />
                </nav>
                <div className="header-btn">
                  {isAuthenticated ? (
                    <Link href="/dashboard" legacyBehavior>
                      <a className="btn-default btn-small round">Dashboard</a>
                    </Link>
                  ) : (
                    <a
                      className="btn-default btn-small round"
                      href={`${baseUrl}/auth/discord`}
                      
                    >
                      Login
                    </a>
                  )}
                </div>
                <div className="header-btn">
                  <a
                    className="btn-default btn-small round"
                    href="https://discord.com/oauth2/authorize?client_id=1139761108471775263&permissions=2416299078&scope=bot%20applications.commands"
                  >
                    <i className="feather-plus-circle"></i>Add Bot
                  </a>
                </div>

                {isAuthenticated && (
                  <div className="account-access rbt-user-wrapper right-align-dropdown">
                    <div className="rbt-user ml--0">
                      {user ? (
                        <a className="admin-img" href="#">
                          <img src={user.avatar_url} alt="User Avatar" />
                        </a>
                      ) : (
                        <div className="admin-img-placeholder"></div>
                      )}
                    </div>
                    <div className="rbt-user-menu-list-wrapper">
                      <UserMenu />
                    </div>
                  </div>
                )}

                <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                  <div className="hamberger">
                    <button
                      className="hamberger-button"
                      onClick={() => setActiveMobileMenu(!activeMobileMenu)}
                    >
                      <i className="feather-menu"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
