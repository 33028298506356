import React, { useEffect, useState } from "react";
import sal from "sal.js";
import PricingData from "../../data/home.json";
import Link from "next/link";
import { Icon } from "@iconify/react";
import checkCircle from "@iconify-icons/fa/check-circle";
import minusCircle from "@iconify-icons/fa/minus-circle";
import FluentEmojiFlatLockedWithKey from '@iconify-icons/fluent-emoji-flat/locked-with-key';
import MajesticonsScanUser from "@iconify-icons/majesticons/scan-user";
import FluentEmojiFlatIdentificationCard from '@iconify-icons/fluent-emoji-flat/identification-card';
import FluentEmojiFlatTimerClock from '@iconify-icons/fluent-emoji-flat/timer-clock';
import FluentEmojiFlatRedExclamationMark from '@iconify-icons/fluent-emoji-flat/red-exclamation-mark';
import LogosDiscordIcon from '@iconify-icons/logos/discord-icon';
import FluentEmojiFlatRedHeart from '@iconify-icons/fluent-emoji-flat/red-heart';
import FluentEmojiFlatMoneyBag from '@iconify-icons/fluent-emoji-flat/money-bag';

const iconMap = {
  checkCircle,
  minusCircle,
  FluentEmojiFlatLockedWithKey,
  MajesticonsScanUser,
  FluentEmojiFlatIdentificationCard,
  FluentEmojiFlatTimerClock,
  FluentEmojiFlatRedExclamationMark,
  FluentEmojiFlatRedHeart,
  LogosDiscordIcon,
  FluentEmojiFlatMoneyBag
};

const Pricing = () => {
  const [showCards, setShowCards] = useState(3); // Show first 3 by default

  useEffect(() => {
    sal();

    const cards = document.querySelectorAll(".bg-flashlight");

    cards.forEach((bgflashlight) => {
      bgflashlight.onmousemove = function (e) {
        let x = e.pageX - bgflashlight.offsetLeft;
        let y = e.pageY - bgflashlight.offsetTop;

        bgflashlight.style.setProperty("--x", x + "px");
        bgflashlight.style.setProperty("--y", y + "px");
      };
    });
  }, []);

  const toggleShowCards = () => {
    setShowCards(showCards === 3 ? PricingData.oneTimePurchases.length : 3);
  };

  return (
    <>
      <div className="rainbow-pricing-area rainbow-section-gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-center"
                data-sal="slide-up"
                data-sal-duration="400"
                data-sal-delay="150"
              >
                <h4 className="subtitle">
                  <span className="theme-gradient">Pricing</span>
                </h4>
                <h2 className="title w-600 mb--20">
                  Secure Your Discord With <span className="theme-gradient">Agely</span>
                </h2>
                <p className="description b1">
                  Harness the most sophisticated AI for age verification on Discord. Ensure safety and privacy with Agely's cutting-edge facial recognition technology.
                </p>
              </div>
            </div>
          </div>

          {/* Subscription Pricing Card */}
          <div className="row justify-content-center row--15 mb-5">
            {PricingData &&
              PricingData.pricing.slice(0, 1).map((data, index) => (
                <div className="col-lg-4 col-md-6 col-12 mx-auto" key={index}>
                  <div
                    className={`rainbow-pricing style-2 ${data.isActive ? "active" : ""}`}
                  >
                    <div className="pricing-table-inner bg-flashlight">
                      <div className="pricing-header">
                        <h4 className="title">{data.title}</h4>
                        <div className="pricing">
                          <div className="price-wrapper">
                            {data.price === 0 ? "" : <span className="currency">$</span>}
                            <span className="price">
                              {data.price === 0 ? "Free" : data.price}
                            </span>
                          </div>
                          <span className="subtitle">{data.subTitle}</span>
                        </div>
                      </div>
                      <div className="separator-animated animated-true mt--30 mb--30"></div>
                      <div className="pricing-body">
                        <ul className="list-style--1">
                          {data.subItem.map((innerData, innerIndex) => (
                            <li key={innerIndex} className="pricing-list-item">
                              <Icon icon={iconMap[innerData.icon]} className="pricing-icon pe-2" />
                              <span className="pricing-text">{innerData.text}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="pricing-footer">
                        {data.price === 0 ? (
                          <Link className="btn-default btn-border" href={data.url}>
                            Try it now
                          </Link>
                        ) : (
                          <Link
                            className={`btn-default ${!data.isActive ? "btn-border" : ""}`}
                            href={data.url}
                          >
                            <Icon icon= {iconMap["LogosDiscordIcon"]} className="pricing-icon pe-2"/> Subscribe 
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {/* One-Time Purchase Options */}
          <div className="row justify-content-center row--15">
            <div className="col-lg-12">
              <div
                className="section-title text-center"
                data-sal="slide-up"
                data-sal-duration="400"
                data-sal-delay="150"
              >
                <h4 className="subtitle">
                  <span className="theme-gradient">Usage Recharges</span>
                </h4>
                <p className="description b1">
                  Run out of usage in your subscription? No problem! Purchase additional usage that never expires (unless you use it all up).
                  You must have the Agely Access subscription to use these one-time purchases.
                </p>
              </div>
            </div>
            {PricingData &&
              PricingData.oneTimePurchases.slice(0, showCards).map((data, index) => (
                <div className="col-lg-4 col-md-6 col-12 mx-auto" key={index}>
                  <div className={`rainbow-pricing style-2 ${data.isActive ? "active" : ""}`}>
                    <div className="pricing-table-inner bg-flashlight">
                      <div className="pricing-header">
                        <h4 className="title">{data.title}</h4>
                        <div className="pricing">
                          <div className="price-wrapper">
                            {data.price === 0 ? "" : <span className="currency">$</span>}
                            <span className="price">
                              {data.price === 0 ? "Free" : data.price}
                            </span>
                          </div>
                          <span className="subtitle">{data.subTitle}</span>
                        </div>
                      </div>
                      <div className="separator-animated animated-true mt--30 mb--30"></div>
                      <div className="pricing-body">
                        <ul className="list-style--1">
                          {data.subItem.map((innerData, innerIndex) => (
                            <li key={innerIndex} className="pricing-list-item">
                              <Icon icon={iconMap[innerData.icon]} className="pricing-icon pe-2" />
                              <span className="pricing-text">{innerData.text}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="pricing-footer">
                        {data.price === 0 ? (
                          <Link className="btn-default btn-border" href={data.url}>
                            Try it now
                          </Link>
                        ) : (
                          <Link
                            className={`btn-default ${!data.isActive ? "btn-border" : ""}`}
                            href={data.url}
                          >
                            <Icon icon= {iconMap["LogosDiscordIcon"]}className="pricing-icon pe-2"/> Purchase
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {/* Toggle Button */}
          <div className="row">
            <div className="col-12 text-center">
              <button className="btn btn-primary mt-4" onClick={toggleShowCards}>
                {showCards === 3 ? "Show More Options" : "Show Less Options"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
