import Link from "next/link";
import React from "react";

const Copyright = () => {
  return (
    <>
      <div className="copyright-area copyright-style-one">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-8 col-sm-12 col-12">
              <div className="copyright-left">
                <ul className="ft-menu link-hover">
                  
                  <li>
                    <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=e4a4c3ba-6c78-49af-99d8-c4b3a9c239cc">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=03a392a6-30cb-4ee7-b864-183c0096f777">Terms of Service</a>
                  </li>
                  <li>
                    <a href="https://discord.gg/DMSnDfrjCd">Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-4 col-sm-12 col-12">
              <div className="copyright-right text-center text-lg-end">
                <p className="copyright-text">
                  Copyright © 2024
                  <Link
                    href="https://agely.xyz/"
                    className="btn-read-more ps-2"
                  >
                    <span>Agely.</span>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Copyright;
